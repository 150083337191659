import { Dispatch } from 'redux';
import * as queryString from 'query-string-for-all';

import { GET_USER_CONFIG, GET_USER_PROFILE } from './types/userProfileTypes';
import { IUserConfig, IUserProfile } from '../entities/IUserProfile';
import { IState } from '../reducers';
import { apiUrl } from '../configuration/url';
import { IStandardSearchBody } from '../entities/IGlobal';

export const getCurrentUser = (currentToken?: string): Promise<IUserProfile> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`${apiUrl}/api/user/me`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': currentToken || token || ''
        }
    }).then((article: any) => article.json()).then((response: IUserProfile) => {
        dispatch({
            type: GET_USER_PROFILE,
            userProfile: response
        });
        getUserConfig();
        return response;
    });
}) as any;

export const getUserById = (userId: string): Promise<IUserProfile> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`${apiUrl}/api/user/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        }
    }).then((userResponse: any) => userResponse.json()).then((response: IUserProfile) => {
        return response;
    });
}) as any;

export const searchUsers = (payload: {name?: string, textSearch?: string, userIds?: string[], page?: number}, size?: number): Promise<IUserProfile[]> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    const queryParams = queryString.stringify(payload);
    return fetch(`${apiUrl}/api/user?sort=nickName&size=${size || 9999}&${queryParams}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        }
    }).then((users: any) => users.json()).then((response: IStandardSearchBody<IUserProfile>) => {
        return response;
    });
}) as any;

export const updateUserData = (userId: string | undefined, body: IUserProfile | undefined): Promise<IUserProfile> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`${apiUrl}/api/user/${userId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        },
        body: JSON.stringify(body)
    }).then((article: any) => article.json()).then((response: IUserProfile) => {
        if (response.id) {
            dispatch({
                type: GET_USER_PROFILE,
                userProfile: response
            });
        }
        return response;
    });
}) as any;

export const deleteUser = (): Promise<any> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`${apiUrl}/api/cleanup`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        }
    }).then(() => {
        return true;
    });
}) as any;

export const getUserConfig = (): Promise<IUserConfig> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`${apiUrl}/api/user/config`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        }
    }).then((userResponse: any) => userResponse.json()).then((response: IUserConfig) => {
        dispatch({
            type: GET_USER_CONFIG,
            userConfig: response
        });
        return response;
    });
}) as any;