import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pl';
import { ClearCacheProvider } from 'react-clear-cache';

import { Layout } from './components/common/Layout';
import { GlobalStyle } from './style/global';
import store from './tools/store';
import { Navigation } from './components/routes';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

moment.locale('pl');

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <ClearCacheProvider duration={300000} auto>
        <Provider store={store}>
            <GlobalStyle />
            <BrowserRouter>
                <Layout>
                    <Navigation />
                </Layout>
            </BrowserRouter>
        </Provider>
    </ClearCacheProvider>
);
serviceWorkerRegistration.register();
